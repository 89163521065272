import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Helmet } from 'react-helmet'
import {Link} from 'gatsby'

import imgLivreBlancCompl from '../assets/images/livre_blanc_compliance.png'
import imgLivreBlancFSC from '../assets/images/livret-fsc.png'
import imgLivreBlancRemontees from '../assets/images/livret-remontees.png'

import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const kebabCase = string => string
    .normalize("NFD").replace(/\p{Diacritic}/gu, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();





const LivresBlancs = ({ data }) => {
    
    return (
        <Layout>
            <Helmet>
                <title>Livres Blanc Seekreet</title>
                <meta name="description" content="..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Livres Blancs"
                homePageText="Accueil" 
                homePageUrl="/" 
                parentActive="Blog"
                parentPageUrl="/blog"
                activePageText="Livres Blancs" 
            />
            <div className="blog-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-blog-post bg-compliance">
                                        <div className="post-image">
                                            <Link to="/livre-blanc-compliance">
                                                <img src={imgLivreBlancCompl} alt="livre blanc compliance" />
                                            </Link>
                                        </div>
                                        <div className="post-content">
                                            <h3>
                                                <Link to="/livre-blanc-compliance">
                                                    Comment intégrer un dispositif de signalement dans votre programme compliance ? 
                                                </Link>
                                            </h3>
                                            <div className="btn-box section-title pt-30" style={{marginBottom:'10px'}}>
                                                <Link to="/livre-blanc-compliance" className="default-btn">
                                                    <i className="flaticon-right"></i> 
                                                    Télécharger <span></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-blog-post bg-fsc">
                                        <div className="post-image">
                                            <Link to="/livre-blanc-fsc">
                                                <img src={imgLivreBlancFSC} alt="livre blanc FSC" />
                                            </Link>
                                        </div>
                                        <div className="post-content">
                                            <h3>
                                                <Link to="/livre-blanc-fsc">
                                                    Les points clés pour la mise en place d’une culture qualité et sécurité des aliments
                                                </Link>
                                            </h3>
                                            <div className="btn-box section-title pt-30" style={{marginBottom:'10px'}}>
                                                <Link to="/livre-blanc-fsc" className="default-btn">
                                                    <i className="flaticon-right"></i> 
                                                    Télécharger <span></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-blog-post bg-relext">
                                        <div className="post-image">
                                            <Link to="/livre-blanc-remontees">
                                                <img src={imgLivreBlancRemontees} alt="livre blanc remontées d'information" />
                                            </Link>
                                        </div>
                                        <div className="post-content">
                                            <h3>
                                                <Link to="/livre-blanc-remontees">
                                                    Comment fluidifier et sécuriser vos remontées d'information grâce au digital ?
                                                </Link>
                                            </h3>
                                            <div className="btn-box section-title pt-30" style={{marginBottom:'10px'}}>
                                                <Link to="/livre-blanc-remontees" className="default-btn">
                                                    <i className="flaticon-right"></i> 
                                                    Télécharger <span></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="widget-area">
                                <div className="widget widget_tracer_posts_thumb">
                                    <h3 className="widget-title">Nos derniers articles</h3>
                                    {data.lastThree.nodes.map(node => (
                                        <article className="item" key={node.id}>
                                            <Link to={"/"+node.frontmatter.slug} className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <GatsbyImage
                                                        image={getImage(node.frontmatter.hero_image)}
                                                        alt={node.frontmatter.hero_image_alt}
                                                        className='vignette-article'
                                                    />
                                                </span>

                                            </Link>
                                            <div className="info">
                                                <span>{node.frontmatter.date}</span>
                                                <h4 className="title usmall">
                                                    <Link to={"/"+node.frontmatter.slug}>
                                                        {node.frontmatter.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    ))}
                                </div>
                                {/** Affichage des catégories */}
                                <div className="widget widget_categories">
                                    <h3 className="widget-title">Catégories</h3>
                                    <div className="tagcloud-custom">
                                        {data.cats.group.map(cat => (
                                            <Link to={"/cat/"+kebabCase(cat.category)} className={"default "+kebabCase(cat.category)} key={cat.category}>
                                                {cat.category} <span className="tag-link-count">{"("+cat.totalCount+")"}</span>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="tagcloud-all center">
                                        <Link to="/blog">
                                        Tous les articles
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}


export const query = graphql`
query {

    lastThree: allMdx (limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            slug
            date(formatString: "MMM YYYY", locale: "fr")
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
    }
    cats: allMdx{
        group(field: frontmatter___category) {
			category: fieldValue
            totalCount
        }
    }
}   
`




export default LivresBlancs;